import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';

const Notifications = () => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (user && user.uid) {
        try {
          const userId = user.uid;
          const url = `${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/notifications`;
          const response = await axios.get(url);
          console.log('Notificações encontradas: ', response.data);
          setNotifications(response.data);
        } catch (error) {
          console.error('Erro ao buscar notificações:', error);
          setNotifications([]);
        }
      } else {
        console.error('UID do usuário não encontrado.');
      }
    };

    fetchNotifications();
  }, [user]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      const userId = user.uid;
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/notifications/${notificationId}`;
      await axios.patch(url, { status: 'read' });
      
      // Atualiza o estado das notificações localmente
      setNotifications(notifications.map(notification =>
        notification._id === notificationId ? { ...notification, status: 'read' } : notification
      ));
      
      console.log(`Notificação com ID ${notificationId} marcada como lida.`);
    } catch (error) {
      console.error('Erro ao marcar notificação como lida:', error);
    }
  };

  return (
    <div style={{ cursor: 'pointer', zIndex: 1001 }}>
      <div onClick={toggleModal} style={{ display: 'flex', alignItems: 'center' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style={{ fill: '#00c3ff' }}>
          <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"></path>
        </svg>
      </div>
      {showModal && (
        <div style={{
          position: 'absolute',
          top: '80px',
          right: '0',
          background: '#fff',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          zIndex: 1001,
          width: '300px'
        }}>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <div key={index} style={{
                border: '1px solid #00c3ff',
                borderRadius: '5px',
                padding: '10px',
                marginTop: index !== 0 ? '10px' : '0',
                color: notification.status === 'unread' ? '#333' : '#aaa',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <span>{notification.message}</span>
                <button
                  onClick={() => handleMarkAsRead(notification._id)}
                  disabled={notification.status === 'read'}
                  style={{
                    marginLeft: '10px',
                    backgroundColor: notification.status === 'unread' ? '#00c3ff' : '#aaa',
                    color: '#fff',
                    border: 'none',
                    padding: '5px 10px',
                    cursor: notification.status === 'unread' ? 'pointer' : 'default'
                  }}
                >
                  {notification.status === 'unread' ? 'Mark as Read' : 'Read'}
                </button>
              </div>
            ))
          ) : (
            <div>Sem Notificações</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
