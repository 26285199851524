import React from 'react';
import './styles.css';
import LogoSvg from './logo/LogoSvg';

const Lp = () => {
  return (
    <div className="lphnn">
      <div className="logo-container">
        <a href={process.env.REACT_APP_HOME_URL}>
          <LogoSvg />
        </a>
      </div>
      <div className="tent">
        <h1>Welcome to LabConect!</h1>
      </div>
      <div className="login">
        <button onClick={() => window.location.href='/login'}>Start</button>
      </div>
    </div>
  );
};

export default Lp;
