import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import LoginPage from '../pages/Login';
import ResetPassword from '../components/Login/ResetPassword';
import SignUp from '../components/Login/SignUp';
import Hall from '../pages/Hall';
import MinhasAnalises from '../pages/MinhasAnalises';
import NovaAnalise from '../pages/NovaAnalise';
import Chat from '../pages/Chat';
import ProtectedRoute from '../components/ProtectedRoute';
import Exit from '../components/Notifications/Exit';
import AnaliseFull from '../pages/AnaliseFull';
import { useAuth } from '../hooks/useAuth';

const Loading = () => (
  <div className="loading">Loading...</div>
);

const AppRoutes = () => {
  const { loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/hall"
          element={
            <ProtectedRoute>
              <Hall />
            </ProtectedRoute>
          }
        />
        <Route
          path="/minhas-analises"
          element={
            <ProtectedRoute>
              <MinhasAnalises />
            </ProtectedRoute>
          }
        />
        <Route
          path="/logout"
          element={
            <ProtectedRoute>
              <Exit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/nova-analise"
          element={
            <ProtectedRoute>
              <NovaAnalise />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />
        <Route
          path="/analise-full/:id" // Aqui estamos usando o parâmetro de rota :id
          element={
            <ProtectedRoute>
              <AnaliseFull /> {/* Componente que exibe a análise completa */}
            </ProtectedRoute>
          }
        />
    
      </Routes>
    </Router>
  );
};

export default AppRoutes;
