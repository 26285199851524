import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './AnaliseFull.css';

const AnaliseFull = () => {
  const { id } = useParams(); // Captura o ID da análise da URL
  const [analise, setAnalise] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Estado para controle dos modais
  const [showModal, setShowModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false); // Controle do modal de chat
  const [tituloInformacao, setTituloInformacao] = useState('');
  const [novaInformacao, setNovaInformacao] = useState('');
  const [formError, setFormError] = useState('');
  const [chatMessages, setChatMessages] = useState([]);

  // Estado para o campo de mensagem no chat
  const [mensagem, setMensagem] = useState('');

  useEffect(() => {
    const fetchAnalise = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}`);
        setAnalise(response.data);
        setLoading(false);
      } catch (error) {
        setError('Erro ao carregar a análise.');
        setLoading(false);
      }
    };

    const fetchChat = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}/chat`);
        setChatMessages(response.data);
      } catch (error) {
        console.error('Erro ao carregar o chat:', error);
      }
    };

    fetchAnalise();
    fetchChat();
  }, [id]);


  
  // Função para adicionar informações
  const handleAddInfo = async () => {
    if (!tituloInformacao || !novaInformacao) {
      setFormError('Preencha todos os campos.');
      return;
    }

    try {
      const newInfo = { title: tituloInformacao, content: novaInformacao };

      // Envia as novas informações para o backend
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}/add-info`, newInfo);

      // Atualiza o estado local
      setAnalise(prevAnalise => ({
        ...prevAnalise,
        analysisData: prevAnalise.analysisData.map(data => ({
          ...data,
          additionalInfo: [...(data.additionalInfo || []), newInfo],
        })),
      }));

      setTituloInformacao('');
      setNovaInformacao('');
      setShowModal(false);
      setFormError('');
    } catch (error) {
      console.error('Erro ao adicionar a nova informação:', error);
      setFormError('Erro ao adicionar a informação.');
    }
  };

  // Função para fechar o modal de chat ao clicar fora
  const handleCloseChatModal = (e) => {
    if (e.target.className === 'chat-modal-overlay') {
      setShowChatModal(false);
    }
  };

  // Função para enviar mensagem no chat
  const handleSendMessage = async () => {
    if (mensagem.trim() === '') return; // Impede mensagens vazias
    const newMessage = {
      sender: 'solicitante', // Aqui definimos que o cliente está enviando a mensagem
      message: mensagem,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${id}/chat`, newMessage);
      setChatMessages(response.data); // Atualiza o chat com a nova mensagem
      setMensagem(''); // Limpa o campo de mensagem
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };


  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="analise-full-container">
        <div className='all-analises'>

      {analise ? (
        <>
          {/* Parte 1: Imagem e título */}
          <div className="informacoes-adicionais">
            <div className="analise-info">
              <div className="analise-header">
                {analise.analysisData[0].samplePhoto && (
                  <img 
                    className="analise-image" 
                    src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${analise.analysisData[0].samplePhoto}`} 
                    alt="Foto da Amostra" 
                  />
                )}
                <h1>{analise.title}</h1> {/* Título da análise */}
              </div>
              <p><strong>Tipo de Amostra:</strong> {analise.analysisData[0].sampleType}</p>
              <p><strong>Responsável:</strong> {analise.analysisData[0].responsibleName}</p>
              <p><strong>Data de Coleta:</strong> {new Date(analise.analysisData[0].collectionDate).toLocaleDateString()}</p>
              <p><strong>Geolocalização:</strong> {analise.analysisData[0].geolocation}</p>
              <p><strong>Armazenamento:</strong> {analise.analysisData[0].storageType}</p>
              <p><strong>Observações:</strong> {analise.analysisData[0].additionalObservations}</p>
              <p><strong>Aplicação do Resultado:</strong> {analise.analysisData[0].analysisApplication}</p>
            </div>
          </div>

          {/* Parte 3: Informações adicionais */}
          <div className="outras-informacoes">
            <h2>Informações adicionais</h2>

            <button className="add-info-btn" onClick={() => setShowModal(true)}>Adicionar informações</button>

            {analise.analysisData[0].additionalInfo && analise.analysisData[0].additionalInfo.length > 0 ? (
              analise.analysisData[0].additionalInfo.map((info, index) => (
                <div key={index}>
                  <h3>{info.title}</h3>
                  <p>{info.content}</p>
                </div>
              ))
            ) : (
              <p>Nenhuma informação adicional adicionada.</p>
            )}

          </div>

          {/* Modal de Adicionar Informações */}
          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <h3>Adicionar Nova Informação</h3>
                <input 
                  type="text" 
                  placeholder="Título da nova informação" 
                  value={tituloInformacao} 
                  onChange={(e) => setTituloInformacao(e.target.value)} 
                />
                <textarea 
                  placeholder="Nova Informação" 
                  value={novaInformacao} 
                  onChange={(e) => setNovaInformacao(e.target.value)} 
                />
                {formError && <p className="error-message">{formError}</p>}
                <div className="modal-buttons">
                  <button onClick={handleAddInfo}>Salvar</button>
                  <button onClick={() => setShowModal(false)}>Cancelar</button>
                </div>
              </div>
            </div>
          


)}

          {/* Modal de Chat Direto */}
          {showChatModal && (
            <div className="chat-modal-overlay" onClick={handleCloseChatModal}>
              
              <div className="chat-modal">
                <div className='chat-title'>
                    <h2>Chat Direto: {analise.title}</h2>
                </div>
                <div className="chat-body">
              {chatMessages.map((msg, index) => (
                <div key={index} className={`chat-message ${msg.sender}`}>
                  <p>{msg.message}</p>
                  <span className="chat-timestamp">{new Date(msg.timestamp).toLocaleString()}</span>
                </div>
              ))}
            </div>
                <div className="chat-footer">
                  <input 
                    type="text" 
                    placeholder="Digite sua mensagem" 
                    value={mensagem} 
                    onChange={(e) => setMensagem(e.target.value)} 
                  />
                  <button onClick={handleSendMessage}>Enviar</button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="error">Análise não encontrada.</div>
      )}
</div>
      {/* SVG fixado na parte inferior direita */}
      <div className="fixed-svg" onClick={() => setShowChatModal(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" style={{ fill: '#007bff' }}>
          <path d="M16 14h.5c.827 0 1.5-.673 1.5-1.5v-9c0-.827-.673-1.5-1.5-1.5h-13C2.673 2 2 2.673 2 3.5V18l5.333-4H16zm-9.333-2L4 14V4h12v8H6.667z"></path>
          <path d="M20.5 8H20v6.001c0 1.1-.893 1.993-1.99 1.999H8v.5c0 .827.673 1.5 1.5 1.5h7.167L22 22V9.5c0-.827-.673-1.5-1.5-1.5z"></path>
        </svg>
      </div>
    </div>
  );
};

export default AnaliseFull;
