import React, { useEffect } from 'react';
import './Hall.css';
import Cards from '../components/Cards'
import '../components/Cards.css'

const Hall = () => {
  useEffect(() => {
    document.body.style.background = 'none';
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.background = '';
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className="hall-container">
      <Cards />
    </div>
  );
};

export default Hall;
