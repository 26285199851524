import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import './MinhasAnalises.css';

const MinhasAnalises = () => {
  const { user } = useAuth(); // Obtemos o usuário autenticado
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Usuário carregado:', user); // Log para verificar o estado do usuário

    if (user && user.analyses) {
      setLoading(false);
    } else {
      setError('Usuário não autenticado ou sem análises.');
      setLoading(false);
    }
  }, [user]);

  const handleCardClick = (analiseId) => {
    console.log('Análise selecionada com ID:', analiseId); // Log para verificar o ID da análise clicada
    // Redireciona o usuário para a página da análise selecionada usando o id da análise
    window.location.href = `/analise-full/${analiseId}`;
  };

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (error) {
    console.log('Erro encontrado:', error); // Log de erro se houver
    return <div className="error">{error}</div>;
  }

  return (
    <div className="analises-container">
      <h1>Minhas Análises</h1>
      <div className="analises-list">
        {user.analyses && user.analyses.length > 0 ? (
          user.analyses.map((analise, index) => (
            <div 
              key={index} 
              className="analise-card" 
              onClick={() => handleCardClick(analise._id)} // Agora usando handleCardClick
            >
              <h2>{analise.title}</h2>
              <p><strong>Tipo de Amostra:</strong> {analise.analysisData[0].sampleType}</p>
              <p><strong>Responsável:</strong> {analise.analysisData[0].responsibleName}</p>
              <p><strong>Data de Coleta:</strong> {new Date(analise.analysisData[0].collectionDate).toLocaleDateString()}</p>
              <p><strong>Armazenamento:</strong> {analise.analysisData[0].storageType}</p>
              <p><strong>Observações:</strong> {analise.analysisData[0].additionalObservations}</p>
              {analise.analysisData[0].samplePhoto && (
                <div className="image-container">
                  <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${analise.analysisData[0].samplePhoto}`} alt="Foto da Amostra" />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="empty-message">
            <p>Você ainda não tem análises.</p>
          </div>
        )}
      </div>
      <button className="nova-analise-btn" onClick={() => window.location.href='/nova-analise'}>
        Solicitar nova análise
      </button>
    </div>
  );
};

export default MinhasAnalises;
