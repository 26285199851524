import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom'; // Importando useNavigate
import './NovaAnalise.css';

const NovaAnalise = () => {
  const { user } = useAuth();
  const navigate = useNavigate(); // Criando uma instância de useNavigate
  const [formData, setFormData] = useState({
    responsavel: '',
    identificacaoAmostra: '',
    dataColeta: '',
    geolocalizacao: '',
    tipoAmostra: '',
    tipoAnalise: '',
    armazenamento: '',
    preTratamento: '',
    volume: '',
    massa: '',
    aspecto: '',
    foto: null,
    observacoes: '',
    aplicacaoResultado: '',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!user || !user.uid) {
      alert('Usuário não autenticado.');
      return;
    }
  
    const data = new FormData();
    for (let key in formData) {
      data.append(key, formData[key]);
    }
  
    try {
      // Use o UID do usuário na rota
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/${user.uid}/analises`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      alert('Análise solicitada com sucesso!');
      
      // Redireciona para "Minhas Análises" após o sucesso
      navigate('/minhas-analises');
    } catch (error) {
      console.error('Erro ao solicitar análise:', error);
      alert('Erro ao solicitar análise.');
    }
  };

  return (
    <div>
      <div className="nova-analise-container">
        <h1>Solicitar Nova Análise</h1>
        <form className="nova-analise-form" onSubmit={handleSubmit}>
          <div className="form-content">
            <div className="form-group">
              <label>Nome do Responsável</label>
              <input type="text" name="responsavel" value={formData.responsavel} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Identificação da Amostra</label>
              <input type="text" name="identificacaoAmostra" value={formData.identificacaoAmostra} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Data de Coleta</label>
              <input type="date" name="dataColeta" value={formData.dataColeta} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Geolocalização</label>
              <input type="text" name="geolocalizacao" value={formData.geolocalizacao} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Tipo de Amostra</label>
              <select name="tipoAmostra" value={formData.tipoAmostra} onChange={handleChange} required>
                <option value="">Selecione</option>
                <option value="agua">Água</option>
                <option value="alimento">Alimento</option>
                <option value="tecido">Tecido Animal</option>
                <option value="solo">Solo</option>
              </select>
            </div>

            <div className="form-group">
              <label>Tipo de Análise</label>
              <input type="text" name="tipoAnalise" value={formData.tipoAnalise} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Armazenamento</label>
              <input type="text" name="armazenamento" value={formData.armazenamento} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Pré-tratamento</label>
              <input type="text" name="preTratamento" value={formData.preTratamento} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Volume</label>
              <input type="number" name="volume" value={formData.volume} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Massa</label>
              <input type="number" name="massa" value={formData.massa} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Aspecto da Amostra</label>
              <input type="text" name="aspecto" value={formData.aspecto} onChange={handleChange} required />
            </div>

            <div className="form-group">
              <label>Foto da Amostra</label>
              <input type="file" name="foto" onChange={handleChange} />
            </div>

            <div className="form-group">
              <label>Observações</label>
              <textarea name="observacoes" value={formData.observacoes} onChange={handleChange} required ></textarea>
            </div>

            <div className="form-group">
              <label>Aplicação do Resultado (opcional)</label>
              <textarea name="aplicacaoResultado" value={formData.aplicacaoResultado} onChange={handleChange}></textarea>
            </div>

            <button type="submit" className="solicitar-btn">Solicitar Análise</button>
          </div>
        </form>
      </div>

      <button className="minhas-analises-btn" onClick={() => navigate('/minhas-analises')}>
        Minhas Análises
      </button>
    </div>
  );
};

export default NovaAnalise;
