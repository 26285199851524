// src/components/Nav/Nav.jsx
import React from 'react';
import Notifications from '../components/Notifications/Notifications';
import Exit from '../components/Notifications/Exit';

import './Nav.css'; 

const Nav = () => {
  return (
    <div className="nav-container">
        <Notifications />
        <Exit />
    </div>
  );
};

export default Nav;
